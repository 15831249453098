<template>
  <div class="pageMain">
    <div class="flex">
      <!-- 组织项目树 -->

      <CustomTables
          :data="tableData"
         
          :total="total"
          class="tableHear"
          :headerCellStyle="headerCellStyle"
          @handleCurrentChanges="handleCurrentChanges"
          @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
          :titleIcon="true"
        >
          <template slot="search">
            <SearchLeft
              @search="search"
              @reset="reset"
              :infoShow="true"
              info="项目信息"
              :iconShow="true"
            >
            <el-form :inline="true" :model="searchForm">
              <el-form-item label="" class="formItem">
                <el-input
                  v-model="searchForm.projName"
                  size="small"
                  placeholder="请输入项目名称"
                  @input="change"
                ></el-input>
              </el-form-item>
            </el-form>
            </SearchLeft>
          </template>
          <template slot="title">
            <div class="title">今日报警({{ 506 }})</div>
          </template>
          <el-table-column type="index" label="排序" width="50" align="center">
          </el-table-column>
          <el-table-column prop="projName" label="项目名称" align="center"> </el-table-column>
          <el-table-column prop="deviceCount" label="总数（人）" align="center"> </el-table-column>
        </CustomTables>

      <!--右边列表  -->
      <div class="form">
        <SearchHead
          @search="search"
          @reset="reset"
          :isExport="true"
          @tableExport="tableExport"
        >
          <el-form :inline="true" :model="searchForm2">
            <el-form-item label="司机姓名">
              <el-input
                v-model="searchForm2.operName"
                size="small"
                placeholder="司机姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="塔机编号">
              <el-input
                v-model="searchForm2.deviceImei"
                size="small"
                placeholder="塔机编号"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="开始日期">
              <el-date-picker
                v-model="searchForm.user"
                type="datetime"
                placeholder="选择开始日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束日期">
              <el-date-picker
                v-model="searchForm.user"
                type="datetime"
                placeholder="选择结束日期"
              >
              </el-date-picker>
            </el-form-item> -->
          </el-form>
        </SearchHead>
        <CustomTables
          :data="tableData2"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          @handleCurrentChange="handleCurrentChange2"
      @handleSizeChange="handleSizeChange2"
        
          :total="total2"
          title="识别信息"
        >
          <!-- <template slot="btns">
              <el-button
                type="success"
                size="small"
                @click="addFrom"
                icon="el-icon-plus"
               
                class="btn"
                >新建</el-button
              >
            </template> -->

          <el-table-column type="selection" align="center" width="55">
          </el-table-column>
          <el-table-column prop="operName" label="司机姓名" align="center">
          </el-table-column>
          <el-table-column prop="operSex" label="性别" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.operSex == 0">男</span>
              <span v-if="scope.row.operSex == 1">女</span>
            </template>
          </el-table-column>
          <el-table-column prop="operName" label="负责人" align="center">
          </el-table-column>
          <el-table-column prop="operTel" label="联系方式" align="center">
          </el-table-column>
          <el-table-column
          show-overflow-tooltip
            prop="deviceImei"
            label="机械现场编号"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="startTime" label="上工识别时间" align="center" show-overflow-tooltip>
          </el-table-column>
          <el-table-column
            prop="startImgUrl"
            label="上工识别图片"
            align="center"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.startImgUrl">
                <el-image
                  style="width: 50px; height: 50px"
                  :src="scope.row.startImgUrl"
                  fit="contain"
                  @click="showImg(scope.row.startImgUrl)"
                >
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="endTime" label="下工识别时间" align="center" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="endImgUrl" label="下工识别图片" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.endImgUrl">
                <el-image
                  style="width: 50px; height: 50px"
                  :src="scope.row.endImgUrl"
                  fit="contain"
                  @click="showImg(scope.row.endImgUrl)"
                >
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="workTime" label="累计工作时长" align="center">
          </el-table-column>
        </CustomTables>
      </div>
    </div>
  </div>
</template>

<script>
import OriginTree from "../../../components/common/OriginTree.vue";
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import SearchLeft from "../../../components/common/SearchLeft.vue";
import data from "../../../api/modules/data";
import { projectState } from "../../../config/dataStatus";
export default {
  components: {
    OriginTree,
    SearchHead,
    CustomTables,
    SearchLeft,
  },
  name: "statistics",
  data() {
    return {
      searchForm: {},
      tableData: [],
      tableData2: [],
      options: [],
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      searchForm2: {
        currPage: 1,
        pageSize: 10,
      },
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      total: 0,
      total2: 0,
      show: true,
      filterText: "",
      projId:''
    };
  },
  mounted() {
    this.getDeviceCountByPages()
  },
  methods: {
    addFrom() {},
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getDeviceCountByPages()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getDeviceCountByPages()
    },
    handleCurrentChange2(pageIndex) {
      this.searchForm2.currPage = pageIndex
      this.getList(this.projId)
    },
    handleSizeChange2(pageSize) {
      this.searchForm2.pageSize = pageSize
      this.searchForm2.currPage = 1
      this.getList(this.projId)
    },
    handleCurrentChanges(row){
      this.projId = row.projId
      this.getList(row.projId)
    },
    getDeviceCountByPages(){
      data.getDeviceCountByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
        if(res.data.records.length>0){
          this.projId = res.data.records[0].projId
        this.getList(this.projId)
        }
     
      });
    },
    getList(projId) {
      this.searchForm2.projId=projId
      data.getFaceRecordListByPage(this.searchForm2).then((res) => {
        this.total2 = res.data.total;
        this.tableData2 = res.data.records;
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    change(e){
        this.searchForm.projName =e
        this.getDeviceCountByPages();
      },
    reset() {
      this.searchForm2 = {
        currPage: 1,
        pageSize: 10,
        orgaId: this.orgaId,
      };
      this.getList();
    },
    tableExport() {
      // 导出
    },
    showImg(url){
      let html = `<div class="img-alert"><img src="${url}"/></div>`
      this.$alert(html, '图片', {
        dangerouslyUseHTMLString: true
      });

    },
    handleSelectionChange(val) {
      console.log(val);
    },
    detailItem() {
      //详情
    },
  },
};
</script>

<style scoped lang="less">
.flex {
  display: flex;
  // justify-content: space-between;
}
.tree {
  width: 20%;
}
.tableHear {
  width: 25%;
  overflow: auto;
  // background:#e4ebf1
}
.form {
  width: 73%;
  margin-left: 2%;
}
.formItem {
  margin-bottom: 0px;
}
.btn {
  background-color: #008f4d;
  // color: #008f4d;
}

.detail {
  color: #008f4d;
}
</style>
